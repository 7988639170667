import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vant from "vant";
import "vant/lib/index.css";

// import cccsections from "cccsections";
// import "cccsections/lib/cccsections.css";
import { Popup, Checkbox } from "vant";
import "amfe-flexible/index.js";
// import 'video.js/dist/video-js.min.css'
// Vue.use(cccsections);

import Vconsole from "vconsole";
let lurl = window.location.href;

if (lurl.indexOf("vconsole") > -1) {
  new Vconsole();
}

// import Vconsole from "vconsole";

// 所有环境均使用
// new Vconsole();

// import Vconsole from 'vconsole'

// let vConsole = new Vconsole()
// Vue.use(vConsole)

//  beforeRouteEnter(to, from, next) {
//       //to是当前页面,from是从哪里来,next是放行
//       // if (to.query.name) {
//       //   to.meta.title = to.query.name //获取从上个页面传过来的name再进行修改
//       // }
//       next() //切记操作完一定要记得放行,否则无法正常跳转页面
//     }

Vue.use(Vant);
Vue.use(Popup);

Vue.use(Checkbox);;
// Vue.use(Dialog);
Vue.config.productionTip = false;
Vue.config.ignoredElements = ["wx-open-launch-app"];
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
